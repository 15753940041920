<template>
  <vue-final-modal
    class="modal-games-filter"
    :overlayTransition="{ mode: 'in-out', duration: 200 }"
    :contentTransition="{ mode: 'in-out', duration: 200 }"
    clickToClose
    @clickOutside="closeDialog"
  >
    <div class="modal-games-filter__container">
      <atomic-icon class="modal-games-filter__close" id="close" @click="closeDialog" />

      <div class="modal-games-filter__header">
        <div class="modal-games-filter__title">
          {{ getContent(popupsData, defaultLocalePopupsData, 'gamesFilter.title') }}
        </div>

        <div v-if="showResetBtn" class="modal-games-filter__reset" @click="onResetAll">
          {{ getContent(popupsData, defaultLocalePopupsData, 'gamesFilter.resetAll') }}
        </div>
      </div>

      <div class="modal-games-filter__block">
        <div class="modal-games-filter__label">
          {{ getContent(popupsData, defaultLocalePopupsData, 'gamesFilter.feature') }}
        </div>

        <selects-wrappers-responsive-select
          v-model:selectedOptions="selectedFeatures"
          :options="featuresWithIdentity"
          :label="getContent(popupsData, defaultLocalePopupsData, 'gamesFilter.allFeatures')"
          :maxHeight="hideProviders ? '304px' : '400px'"
          maxWidth="336px"
          multiple
        />
      </div>

      <div v-if="!hideProviders" class="modal-games-filter__block">
        <div class="modal-games-filter__label">
          {{ getContent(popupsData, defaultLocalePopupsData, 'gamesFilter.provider') }}
        </div>

        <selects-wrappers-responsive-select
          v-model:selectedOptions="selectedProviders"
          :options="getFilteredProviders"
          :label="getContent(popupsData, defaultLocalePopupsData, 'gamesFilter.allProviders')"
          :maxHeight="'304px'"
          maxWidth="336px"
          multiple
        />
      </div>

      <div class="modal-games-filter__buttons">
        <button-base class="modal-games-filter__button" type="gray" size="md" @click="closeDialog">
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.cancel') }}
        </button-base>

        <button-base
          class="modal-games-filter__button"
          type="primary"
          size="md"
          :isDisabled="applyDisabled"
          @click="onApplyFilter"
        >
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.apply') }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';

  import isEqual from 'lodash/isEqual';

  import { normalizeToArray } from '@skeleton/utils/arrayUtils';

  import { ModalName } from '@skeleton/consts/modals';

  withDefaults(
    defineProps<{
      hideProviders: boolean;
    }>(),
    {
      hideProviders: false,
    }
  );

  const { closeModal } = useModalStore();
  const { getContent } = useProjectMethods();
  const { popupsData, defaultLocalePopupsData, layoutData, defaultLocaleLayoutData } = storeToRefs(useGlobalStore());
  const { getFilteredProviders } = storeToRefs(useGameProvidersStore());
  const { featuresWithIdentity } = storeToRefs(useGameFeaturesStore());

  const route = useRoute();
  const router = useRouter();

  const selectedProviders = ref<string[]>([]);
  const selectedFeatures = ref<string[]>([]);

  const applyDisabled = computed(() => {
    const { providers, features } = route.query;

    const isProvidersDifferent = !isEqual(normalizeToArray(providers), selectedProviders.value);
    const isFeaturesDifferent = !isEqual(normalizeToArray(features), selectedFeatures.value);

    return !isProvidersDifferent && !isFeaturesDifferent;
  });

  const showResetBtn = computed(() => selectedProviders.value.length || selectedFeatures.value.length);

  const parseQuery = () => {
    const { providers = [], features = [] } = route.query;

    selectedProviders.value = normalizeToArray(providers);
    selectedFeatures.value = normalizeToArray(features);
  };

  const closeDialog = () => {
    closeModal(ModalName.GAMES_FILTER);
  };

  const onResetAll = () => {
    selectedProviders.value = [];
    selectedFeatures.value = [];
  };

  const onApplyFilter = () => {
    updateQueryWithReplace();
    closeDialog();
  };

  const updateQueryWithReplace = () => {
    router.replace({
      query: {
        ...route.query,
        providers: selectedProviders.value,
        features: selectedFeatures.value,
      },
    });
  };

  onMounted(() => {
    parseQuery();
  });
</script>

<style src="~/assets/styles/components/modal/games-filter.scss" lang="scss" />
